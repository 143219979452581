<template>
  <ValidationProvider :vid="name" :name="name" :rules="rules" v-slot="{ valid, errors }">
    <template v-if="label">
      <label class="dual-label" v-if="typeof label === 'string'" :for="name">{{
        label
      }}</label>
      <label class="dual-label" v-else :for="name">
        <span>
          {{ label.main }}
        </span>
        <span>
          {{ label.secondary }}
        </span>
      </label>
    </template>
    <date-picker
      :placeholder="placeholder"
      :value="value"
      @input="onInput"
      v-bind="$attrs"
      :state="errors[0] ? false : valid && validate ? true : null"
      :id="$attrs.name"
      :class="[errors[0] ? 'is-invalid' : valid && validate ? 'is-valid' : null]"
      :format="format"
      valueType="format"
      :clearable="false"
      :disabled-date="disabledDate"
    ></date-picker>
  </ValidationProvider>
</template>

<script>
import inputMixin from "@/mixins/inputMixin";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
  mixins: [inputMixin],
  components: { DatePicker },
  props: {
    format: {
      type: String,
      default: "DD-MM-YYYY",
    },
    label: {
      type: [String, Object],
    },
  },
  methods: {
    disabledDate(date) {
      const showingDate = new Date(date).getTime();
      const currentDate = new Date().getTime();
      let disable = false;
      if (this.$attrs.disablePastDates && showingDate < currentDate) {
        disable = true;
      }
      if (this.$attrs.disableWeekends) {
        const day = new Date(date).getDay();
        if (day === 0 || day === 6) disable = true;
      }
      if (
        this.$attrs.disableFutureDates &&
        this.$attrs.futureDateTimestamp &&
        showingDate > this.$attrs.futureDateTimestamp
      ) {
        disable = true;
      }

      if (this.$attrs.disabledDates && this.$attrs.disabledDates.length) {
        this.$attrs.disabledDates.forEach((date) => {
          let x = moment(date, "DD-MM-YYYY");
          let y = moment(showingDate);
          console.log("x,y", x, y);
          return x.isSame(y);
          // console.log("disableDate1", date, showingDate);
          // console.log("equal", moment(date).isSame(showingDate));
          // const disableDate = new Date(date).getTime();
          // console.log("disableDate", disableDate, showingDate);
          // if (disableDate === showingDate) {
          //   disable = true;
          // }
        });
      }
      return disable;
    },
  },
};
</script>
<style lang="scss">
$namespace: "xmx"; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />

$default-color: $gray4;
$primary-color: $primary;

@import "~vue2-datepicker/scss/index.scss";
.mx-datepicker {
  width: auto;
  display: block;

  input {
    padding: 1.395rem 0.8125rem;

    font-size: 1.125rem !important;
    border-color: #c9c9c9 !important;
    color: #7c7c7c !important;
    border-radius: 8px;
    background: #fbfbfb;
    border: 1px solid #c9c9c9 !important;
  }
  &.is-valid {
    input {
      border-color: #bfbfbf !important;
    }
  }
  &.is-invalid {
    input {
      border-color: $danger !important;
    }
  }
}

.dual-label,
label {
  font-weight: 500;
  font-size: 0.875rem;
  span:nth-of-type(2) {
    color: #9ea3ae;
  }
}
</style>
