<template>
  <ValidationProvider
    ref="validator"
    :vid="name"
    :name="name"
    :rules="rules"
    v-slot="{ valid, errors }"
  >
    <b-form-group>
      <template v-if="label">
        <label
          class="dual-label"
          v-if="typeof label === 'string'"
          :for="name"
          >{{ label }}</label
        >
        <label class="dual-label" v-else :for="name">
          <span>
            {{ label.main }}
          </span>
          <span>
            {{ label.secondary }}
          </span>
        </label>
      </template>

      <b-form-select
        :placeholder="placeholder"
        v-bind="$attrs"
        :state="errors[0] ? false : valid && validate ? true : null"
        :value="value"
        @input="onInput"
        :class="customClass"
      >
        <slot />
      </b-form-select>
      <b-form-invalid-feedback v-if="validate" id="inputLiveFeedback">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import inputMixin from "@/mixins/inputMixin";
export default {
  mixins: [inputMixin],
  props: {
    label: {
      type: [String, Object],
    },
  },
};
</script>

<style lang="scss" scoped>
.dual-label,
label {
  font-weight: 500;
  font-size: 0.875rem;
  span:nth-of-type(2) {
    color: #9ea3ae;
  }
}
</style>
